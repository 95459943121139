.footer
  background-color: var(--color-dark)
  color: #fff
  padding: 32px 0

  &__grid
    display: flex

  &__col
    &:not(:last-child)
      margin-right: 80px

    &__head
      font-size: 18px
      font-weight: 500
      margin-bottom: 10px
      white-space: nowrap

    &__links
      li
        &:not(:last-child)
          margin-bottom: 6px

      a
        font-size: 14px
        color: #ddd
        white-space: nowrap

  hr
    border: none
    border-top: 1px solid #3a4553
    margin: 32px 0

  &__copy
    font-size: 14px
    color: #ddd
    text-align: center
