.category-card
  width: 300px
  height: 300px
  flex-shrink: 0
  max-width: 100%
  padding: 24px
  border-radius: 8px
  background-size: cover
  background-position: center
  display: flex
  flex-direction: column
  justify-content: flex-end
  align-items: flex-start
  position: relative
  overflow: hidden
  cursor: pointer

  &::before
    content: ''
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%
    background: linear-gradient(0deg, rgba(#000, .8), transparent)

  &__title,
  .btn
    position: relative
    z-index: 2

  &__title
    font-size: 22px
    color: #3e3e3c
    // margin-bottom: 15px
    line-height: 1.2
    font-weight: 500
    color: #fff
