.features
  padding: 32px
  margin-bottom: 20px
  background-color: #fff
  border-radius: 12px

  h2
    font-size: 24px
    color: #3e3e3c
    margin-bottom: 36px
    line-height: 1
    font-weight: 500
    text-align: center

  &__grid
    display: flex
    justify-content: center
    margin: 0 -20px
