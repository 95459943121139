.product-card
  width: 280px
  &__image
    height: 250px
    background-color: #f5f5f5
    background-size: cover
    background-position: center

  &__details
    background-color: #fafafa
    height: 134px
