.feature
  width: 148px
  margin: 0 20px
  text-align: center

  &__image
    background-color: var(--color-dark-200)
    border-radius: 50%
    display: flex
    align-items: center
    justify-content: center
    width: 100px
    height: 100px
    margin: 0 auto 12px

    svg
      width: 36px
      height: 36px
      color: var(--color-orange)

  &__title
    font-size: 16px
    line-height: 1.2
    font-weight: 500
