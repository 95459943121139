.hero
  background-image: url('../assets/images//hero.jpg')
  background-size: cover
  background-position: top
  position: absolute
  top: 0
  left: 0
  height: 240px
  width: 100%
  &::after
    content: ''
    position: absolute
    bottom: 0
    left: 0
    width: 100%
    height: 80px
    background: linear-gradient(to top, #eaeded, rgba(#eaeded, 0))
