.nav
  background-color: var(--color-dark)
  color: #fff
  height: 60px
  position: fixed
  top: 0
  left: 0
  width: 100%
  z-index: 999

  .container
    display: flex
    align-items: center
    justify-content: space-between
    height: 100%
    width: 100%

  a
    color: inherit
    &:hover
      text-decoration: none

  &__brand
    width: 104px
    margin-right: 36px
    color: #fff
    position: relative
    top: 7px
    cursor: pointer

  &__search
    display: flex
    align-items: center
    margin-right: 24px

    &__input[type=text]
      border: none
      width: 360px
      padding: 0 16px
      line-height: 1
      border-radius: 8px 0 0 8px
      margin-bottom: 0
      height: 42px

    &__btn
      border: none
      outline: none
      background-color: var(--color-orange)
      display: flex
      align-items: center
      justify-content: center
      height: 42px
      width: 42px
      padding: 8px
      border-radius: 0 8px 8px 0
      cursor: pointer

      svg
        width: 18px
        height: 18px
        color: var(--color-dark)

  &__links
    display: flex
    align-items: center
    margin-left: auto

  &__link
    cursor: pointer
    span
      display: block
      margin-right: 24px

    &LineOne
      font-size: 12px

    &LineTwo
      font-size: 14px
      font-weight: 700

  &__cart
    cursor: pointer
    position: relative

    svg
      width: 24px
      height: 24px

    &__count
      position: absolute
      top: -5px
      right: -5px
      background-color: var(--color-orange)
      color: var(--color-dark)
      font-size: 10px
      font-weight: 600
      height: 16px
      width: 16px
      border-radius: 10px
      display: flex
      align-items: center
      justify-content: center
