.btn
  outline: none
  border: none
  border-radius: 8px
  padding: 14px 18px
  font-size: 16px
  font-weight: 500
  background: var(--color-orange)
  color: var(--color-dark)
  cursor: pointer

  &.secondary
    background: linear-gradient(to bottom, #f7f8fa, #e7e9ec)
    border-color: #adb1b8

label
  display: block
  font-size: 15px
  line-height: 1
  margin-bottom: 8px

input[type='text'],
select
  height: 44px
  padding: 12px 16px
  width: 100%
  outline: none
  color: var(--color-dark)
  border: 1px solid #ccc
  border-radius: 8px
  margin-bottom: 16px
  font-size: 15px
