.suggestions
  padding: 32px
  margin-bottom: 20px
  background-color: #fff
  border-radius: 12px
  overflow: hidden

  h2
    font-size: 24px
    font-weight: 500
    margin-bottom: 36px
    line-height: 1

  &__grid
    display: flex
    margin: 0 -20px

  .product__image
    margin: 0 20px
    max-height: 160px
