.auth
  display: flex
  align-items: center
  &__title
    font-size: 36px
    font-weight: 500
    line-height: 1
    margin-bottom: 32px
    text-align: center

  .logo
    display: block
    width: 148px
    margin: 0 auto 32px

  a
    color: #0066c0

  &__card
    width: 360px
    max-width: 100%
    margin: 0 auto

  &__legal
    font-size: 13px
    line-height: 1.4
    margin-bottom: 18px

  &__link
    font-size: 13px

  &__help
    font-size: 13px

  &__sep
    position: relative
    margin: 20px 0 16px
    text-align: center
    span
      font-size: 14px
      color: #767676
      padding: 0 8px
      background-color: #fff
      z-index: 1

    &::before
      content: ''
      width: 100%
      height: 0
      border-top: 1px solid #e7e7e7
      position: absolute
      top: 50%
      left: 0
      z-index: -1

  .btn
    display: block
    width: 100%
    &:not(:last-child)
      margin-bottom: 18px
