.cart
  background-color: #eaeded

  .container
    display: flex
    align-items: flex-start

  h1
    font-size: 28px
    line-height: 1

  p
    font-size: 15px
    color: #222
    font-weight: 600

  &__items
    display: flex
    flex-direction: column
    gap: 24px

  &__products
    background-color: #fff
    border-radius: 4px
    padding: 36px
    width: 70%
    margin-right: 20px

  &__product
    display: flex
    &__details
      max-width: 520px

    &__image
      margin-right: 20px
      width: 180px

    &__title
      font-size: 18px
      font-weight: 500
      margin-bottom: 8px

    &__footer
      display: flex

    .stock__status
      font-size: 16px
      color: var(--color-success)
      margin-bottom: 12px

    .quantity
      font-size: 14px
      margin-right: 12px

    .price
      font-size: 24px
      font-weight: 500
      margin-left: auto
      color: #f3a126

  &__subtotal
    background-color: #fff
    border-radius: 4px
    padding: 24px
    width: calc(30% - 20px)

  .amount
    margin-bottom: 16px
    font-size: 20px
    font-weight: 500

    span
      font-size: 24px
      color: #f3a126

  .cart__links
    display: flex

  .link
    font-size: 13px
    color: #007185
    padding: 0 10px
    border-left: 1px solid #ddd
    cursor: pointer
