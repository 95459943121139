.checkout
  h1
    font-size: 28px
    font-weight: 500

  form
    width: 440px
    max-width: 100%

  .btn
    width: 100%

  h3
    font-size: 17px
    font-weight: 700

  .shipping-to
    color: #888
    font-weight: 500
    font-size: 14px
    margin-bottom: 14px
    line-height: 1.4

  .grid
    display: flex
    justify-content: space-between
    .col
      flex-basis: 44%

  .product
    &__name
      font-size: 14px
      font-weight: 700
      color: #000
      margin-bottom: 4px

    &__price
      font-size: 14px
      font-weight: 700
      color: #b12704

    &__quantity
      font-size: 14px
      font-weight: 500
      color: #888

  .price-and-quantity
    margin-bottom: 14px

  .shipping
    &__option
      display: flex
      margin-bottom: 20px
      input
        margin-right: 8px

    &__time
      font-size: 14px
      font-weight: 700
      color: #090

    &__price
      font-size: 14px
      font-weight: 500
      color: #888
