h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6
  font-weight: 500

h1, .h1
  font-size: 32px

h2, .h2
  font-size: 28px

h3, .h3
  font-size: 24px

h4, .h4
  font-size: 20px
