.categories
  margin-bottom: 32px
  padding-left: 24px
  position: relative

  .categorie__grid
    display: flex
    gap: 20px
    overflow-x: auto
    width: 100%
