@import './sass/grid'
@import './sass/auth'
@import './sass/form'
@import './sass/typography'

*
  box-sizing: border-box
  margin: 0

\:root
  --color-dark: #131921
  --color-dark-200: #232f3e
  --color-orange: #e9aa58
  --color-success: #4caf50

body
  background-color: #fff
  color: #333
  overflow-x: hidden
  font-family: 'Poppins', sans-serif

hr
  border: none
  border-top: 1px solid #e7e7e7
  margin: 20px 0

.page
  min-height: 100vh
  padding: 36px 0
  margin-top: 60px

ul
  list-style-type: none
  padding: 0
  margin: 0

a
  text-decoration: none
